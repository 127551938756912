<template>
  <div class="container">
    <Header
      :showBackButton="true"
      backText="Get More Resources"
      :showLogoutButton="true"
      @back="goBackToResources"
    />
    <div class="content" ref="content">
      <h1>Research Studies</h1>

      <!-- Search Input -->
      <input type="text" v-model="searchQuery" placeholder="Search for a study" @input="saveSearchQuery" />

      <!-- Genre Buttons -->
      <div class="genres-container">
        <button
          v-for="genre in genres"
          :key="genre"
          @click="filterByGenre(genre)"
          class="genre-button"
        >
          {{ genre }}
        </button>
      </div>

      <!-- Research Studies List -->
      <ul class="research-list">
        <li v-for="study in filteredResearchStudies" :key="study.id" class="research-item">
          <router-link :to="{ name: 'choose-action', params: { topicType: 'research', topicId: study.id, topicName: study.title } }">
            {{ study.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      researchStudies: [
      { "id": 1, "title": "Artificial Intelligence in Healthcare: Early Detection of Alzheimer’s Disease through Machine Learning", "text": "/articles/STUDY-Artificial-Intelligence-in-Healthcare-Early-Detection-of-Alzheimer’s-Disease-through-Machine-Learning.txt", "genre": "Artificial Intelligence" },
      { "id": 2, "title": "Artificial Intelligence in Medical Diagnosis: Improving Accuracy and Speed in Healthcare", "text": "/articles/STUDY-Artificial-Intelligence-in-Medical-Diagnosis-Improving-Accuracy-and-Speed-in-Healthcare.txt", "genre": "Artificial Intelligence" },
  { "id": 3, "title": "Caloric Restriction and Longevity: Insights from the Study of Aging in Non-Human Primates", "text": "/articles/STUDY-Caloric-Restriction-and-Longevity-Insights-from-the-Study-of-Aging-in-Non-Human-Primates.txt", "genre": "Aging & Longevity" },
  { "id": 4, "title": "CRISPR-Cas9 and Gene Editing: Unlocking the Potential to Treat Genetic Diseases", "text": "/articles/STUDY-CRISPR-Cas9-and-Gene-Editing-Unlocking-the-Potential-to-Treat-Genetic-Diseases.txt", "genre": "Genetics" },
  { "id": 5, "title": "Exercise and Brain Health: The Role of Aerobic Activity in Enhancing Cognitive Function and Neuroplasticity", "text": "/articles/STUDY-Exercise-and-Brain-Health-The-Role-of-Aerobic-Activity-in-Enhancing-Cognitive-Function-and-Neuroplasticity.txt", "genre": "Neuroscience" },
  { "id": 6, "title": "Gravitational Waves: A New Window into the Universe", "text": "/articles/STUDY-Gravitational-Waves-A-New-Window-into-the-Universe.txt", "genre": "Physics" },
  { "id": 7, "title": "Immunotherapy in Cancer: Harnessing the Body’s Immune System to Fight Tumors", "text": "/articles/STUDY-Immunotherapy-in-Cancer-Harnessing-the-Body’s-Immune-System-to-Fight-Tumors.txt", "genre": "Cancer Research" },
  { "id": 8, "title": "Neuroplasticity", "text": "/articles/STUDY-Neuroplasticity.txt", "genre": "Neuroscience" },
  { "id": 9, "title": "Pharmacogenomics: Personalized Medicine through Genetic Profiling", "text": "/articles/STUDY-Pharmacogenomics-Personalized-Medicine-through-Genetic-Profiling.txt", "genre": "Pharmacology" },
  { "id": 10, "title": "Targeting the Immune System: The Role of Immune Checkpoint Inhibitors in Cancer Treatment", "text": "/articles/STUDY-Targeting-the-Immune-System-The-Role-of-Immune-Checkpoint-Inhibitors-in-Cancer-Treatment.txt", "genre": "Immunology" },
  { "id": 11, "title": "The Development of mRNA Vaccines: A New Frontier in Infectious Disease Prevention", "text": "/articles/STUDY-The-Development-of-mRNA-Vaccines-A-New-Frontier-in-Infectious-Disease-Prevention.txt", "genre": "Biotechnology" },
  { "id": 12, "title": "The Discovery of Graphene: Revolutionizing Materials Science", "text": "/articles/STUDY-The-Discovery-of-Graphene-Revolutionizing-Materials-Science.txt", "genre": "Chemistry" },
  { "id": 13, "title": "The Effects of Climate Change on Polar Ice Sheets: A Study of Melting Rates and Global Sea-Level Rise", "text": "/articles/STUDY-The-Effects-of-Climate-Change-on-Polar-Ice-Sheets-A-Study-of-Melting-Rates-and-Global-Sea-Level-Rise.txt", "genre": "Environmental Science" },
  { "id": 14, "title": "The Effects of Mindfulness Meditation on Reducing Stress and Anxiety: A Randomized Controlled Trial", "text": "/articles/STUDY-The-Effects-of-Mindfulness-Meditation-on-Reducing-Stress-and-Anxiety-A-Randomized-Controlled-Trial.txt", "genre": "Psychology" },
  { "id": 15, "title": "The Global Impact of Vaccination Programs: Eradication of Smallpox and the Fight Against Infectious Diseases", "text": "/articles/STUDY-The-Global-Impact-of-Vaccination-Programs-Eradication-of-Smallpox-and-the-Fight-Against-Infectious-Diseases.txt", "genre": "Public Health" },
  { "id": 16, "title": "The Impact of Clean Water and Sanitation on Reducing Child Mortality: Lessons from Global Health Initiatives", "text": "/articles/STUDY-The-Impact-of-Clean-Water-and-Sanitation-on-Reducing-Child-Mortality-Lessons-from-Global-Health-Initiatives.txt", "genre": "Public Health" },
  { "id": 17, "title": "The Impact of Climate Change on Coral Reefs: Assessing the Consequences of Ocean Warming and Acidification", "text": "/articles/STUDY-The-Impact-of-Climate-Change-on-Coral-Reefs-Assessing-the-Consequences-of-Ocean-Warming-and-Acidification.txt", "genre": "Environmental Science" },
  { "id": 18, "title": "The Impact of Deforestation on Biodiversity: A Global Study of Tropical Rainforests", "text": "/articles/STUDY-The-Impact-of-Deforestation-on-Biodiversity-A-Global-Study-of-Tropical-Rainforests.txt", "genre": "Environmental Science" },
  { "id": 19, "title": "The Impact of Gut Microbiota on Mental Health: Unraveling the Gut-Brain Axis", "text": "/articles/STUDY-The-Impact-of-Gut-Microbiota-on-Mental-Health-Unraveling-the-Gut-Brain-Axis.txt", "genre": "Immunology" },
  { "id": 20, "title": "The Impact of Microbiome Diversity on Immune System Function: A Study of Gut Health and Immunity", "text": "/articles/STUDY-The-Impact-of-Microbiome-Diversity-on-Immune-System-Function-A-Study-of-Gut-Health-and-Immunity.txt", "genre": "Immunology" },
  { "id": 21, "title": "The Impact of Ocean Acidification on Marine Life: Assessing the Consequences of CO2 Emissions", "text": "/articles/STUDY-The-Impact-of-Ocean-Acidification-on-Marine-Life-Assessing-the-Consequences-of-CO2-Emissions.txt", "genre": "Environmental Science" },
  { "id": 22, "title": "The Impact of Vaccination Programs on Eradicating Smallpox: A Global Health Milestone", "text": "/articles/STUDY-The-Impact-of-Vaccination-Programs-on-Eradicating-Smallpox-A-Global-Health-Milestone.txt", "genre": "Public Health" },
  { "id": 23, "title": "The Influence of Social Media on Mental Health: A Study on Depression and Anxiety in Adolescents", "text": "/articles/STUDY-The-Influence-of-Social-Media-on-Mental-Health-A-Study-on-Depression-and-Anxiety-in-Adolescents.txt", "genre": "Psychology" },
  { "id": 24, "title": "The Role of 3D Bioprinting in Organ Regeneration: A Breakthrough in Tissue Engineering", "text": "/articles/STUDY-The-Role-of-3D-Bioprinting-in-Organ-Regeneration-A-Breakthrough-in-Tissue-Engineering.txt", "genre": "Molecular Biology" },
  { "id": 25, "title": "The Role of Antisense Oligonucleotides in Treating Genetic Disorders: A New Frontier in Precision Medicine", "text": "/articles/STUDY-The-Role-of-Antisense-Oligonucleotides-in-Treating-Genetic-Disorders-A-New-Frontier-in-Precision-Medicine.txt", "genre": "Genetics" },
  { "id": 26, "title": "The Role of Artificial Intelligence in Drug Discovery: Revolutionizing the Development of New Medicines", "text": "/articles/STUDY-The-Role-of-Artificial-Intelligence-in-Drug-Discovery-Revolutionizing-the-Development-of-New-Medicines.txt", "genre": "Artificial Intelligence" },
  { "id": 27, "title": "The Role of Artificial Intelligence in Personalized Cancer Treatment: Optimizing Therapies through Predictive Modeling", "text": "/articles/STUDY-The-Role-of-Artificial-Intelligence-in-Personalized-Cancer-Treatment-Optimizing-Therapies-through-Predictive-Modeling.txt", "genre": "Artificial Intelligence" },
  { "id": 28, "title": "The Role of Artificial Intelligence in Predicting Disease Outbreaks: Enhancing Global Health Surveillance", "text": "/articles/STUDY-The-Role-of-Artificial-Intelligence-in-Predicting-Disease-Outbreaks-Enhancing-Global-Health-Surveillance.txt", "genre": "Artificial Intelligence" },
  { "id": 29, "title": "The Role of Artificial Photosynthesis in Carbon Capture: A New Approach to Combating Climate Change", "text": "/articles/STUDY-The-Role-of-Artificial-Photosynthesis-in-Carbon-Capture-A-New-Approach-to-Combating-Climate-Change.txt", "genre": "Environmental Science" },
  { "id": 30, "title": "The Role of Bacteriophage Therapy in Combatting Antibiotic Resistance: A New Era in Infection Treatment", "text": "/articles/STUDY-The-Role-of-Bacteriophage-Therapy-in-Combatting-Antibiotic-Resistance-A-New-Era-in-Infection-Treatment.txt", "genre": "Medical Science" },
  { "id": 31, "title": "The Role of Biodiversity in Ecosystem Resilience: A Study of Ecosystem Stability in Response to Environmental Change", "text": "/articles/STUDY-The-Role-of-Biodiversity-in-Ecosystem-Resilience-A-Study-of-Ecosystem-Stability-in-Response-to-Environmental-Change.txt", "genre": "Environmental Science" },
  { "id": 32, "title": "The Role of Bioprinting in Organ Regeneration: Revolutionizing Transplant Medicine", "text": "/articles/STUDY-The-Role-of-Bioprinting-in-Organ-Regeneration-Revolutionizing-Transplant-Medicine.txt", "genre": "Molecular Biology" },
  { "id": 33, "title": "The Role of CAR-T Cell Therapy in Treating Blood Cancers: A Revolution in Immunotherapy", "text": "/articles/STUDY-The-Role-of-CAR-T-Cell-Therapy-in-Treating-Blood-Cancers-A-Revolution-in-Immunotherapy.txt", "genre": "Cancer Research" },
  { "id": 34, "title": "The Role of CRISPR-Cas12 in Enhancing Antiviral Therapies: A Study in Precision Medicine", "text": "/articles/STUDY-The-Role-of-CRISPR-Cas12-in-Enhancing-Antiviral-Therapies-A-Study-in-Precision-Medicine.txt", "genre": "Genetics" },
  { "id": 35, "title": "The Role of CRISPR-Cas13 in RNA Editing: A New Frontier in Genetic Therapies", "text": "/articles/STUDY-The-Role-of-CRISPR-Cas13-in-RNA-Editing-A-New-Frontier-in-Genetic-Therapies.txt", "genre": "Genetics" },
  { "id": 36, "title": "The Role of Epigenetic Reprogramming in Aging: Unlocking the Secrets of Cellular Longevity", "text": "/articles/STUDY-The-Role-of-Epigenetic-Reprogramming-in-Aging-Unlocking-the-Secrets-of-Cellular-Longevity.txt", "genre": "Genetics" },
  { "id": 37, "title": "The Role of Epigenetics in Cancer: How Environmental Factors Influence Gene Expression", "text": "/articles/STUDY-The-Role-of-Epigenetics-in-Cancer-How-Environmental-Factors-Influence-Gene-Expression.txt", "genre": "Genetics" },
  { "id": 38, "title": "The Role of Gene Therapy in Treating Hemophilia: A Breakthrough in Genetic Medicine", "text": "/articles/STUDY-The-Role-of-Gene-Therapy-in-Treating-Hemophilia-A-Breakthrough-in-Genetic-Medicine.txt", "genre": "Genetics" },
  { "id": 39, "title": "The Role of Genetic Testing in Personalized Medicine: Tailoring Treatments to Individual Patients", "text": "/articles/STUDY-The-Role-of-Genetic-Testing-in-Personalized-Medicine-Tailoring-Treatments-to-Individual-Patients.txt", "genre": "Genetics" },
  { "id": 40, "title": "The Role of Gut-Brain Axis in Neurodegenerative Diseases: Understanding the Link Between Gut Microbiota and Cognitive Decline", "text": "/articles/STUDY-The-Role-of-Gut-Brain-Axis-in-Neurodegenerative-Diseases-Understanding-the-Link-Between-Gut-Microbiota-and-Cognitive-Decline.txt", "genre": "Neuroscience" },
  { "id": 41, "title": "The Role of Immunotherapy in Treating Melanoma: The Success of PD-1 Inhibitors", "text": "/articles/STUDY-The-Role-of-Immunotherapy-in-Treating-Melanoma-The-Success-of-PD-1-Inhibitors.txt", "genre": "Immunology" },
  { "id": 42, "title": "The Role of Microbial Therapy in Treating Inflammatory Bowel Disease: Harnessing the Power of the Gut Microbiome", "text": "/articles/STUDY-The-Role-of-Microbial-Therapy-in-Treating-Inflammatory-Bowel-Disease-Harnessing-the-Power-of-the-Gut-Microbiome.txt", "genre": "Immunology" },
  { "id": 43, "title": "The Role of Mitochondrial DNA in Aging: Unraveling the Link Between Energy Production and Longevity", "text": "/articles/STUDY-The-Role-of-Mitochondrial-DNA-in-Aging-Unraveling-the-Link-Between-Energy-Production-and-Longevity.txt", "genre": "Molecular Biology" },
  { "id": 44, "title": "The Role of Nanotechnology in Cancer Treatment: Targeted Drug Delivery and Enhanced Therapeutics", "text": "/articles/STUDY-The-Role-of-Nanotechnology-in-Cancer-Treatment-Targeted-Drug-Delivery-and-Enhanced-Therapeutics.txt", "genre": "Cancer Research" },
  { "id": 45, "title": "The Role of Optogenetics in Neuroscience: Unlocking the Brain’s Secrets with Light", "text": "/articles/STUDY-The-Role-of-Optogenetics-in-Neuroscience-Unlocking-the-Brain’s-Secrets-with-Light.txt", "genre": "Neuroscience" },
  { "id": 46, "title": "The Role of Quantum Computing in Drug Discovery: Accelerating the Development of New Medicines", "text": "/articles/STUDY-The-Role-of-Quantum-Computing-in-Drug-Discovery-Accelerating-the-Development-of-New-Medicines.txt", "genre": "Artificial Intelligence" },
  { "id": 47, "title": "The Role of Senolytic Therapies in Combating Age-Related Diseases: Targeting Senescent Cells for Healthier Aging", "text": "/articles/STUDY-The-Role-of-Senolytic-Therapies-in-Combating-Age-Related-Diseases-Targeting-Senescent-Cells-for-Healthier-Aging.txt", "genre": "Aging & Longevity" },
  { "id": 48, "title": "The Role of Sleep in Cognitive Performance: Understanding How Sleep Affects Memory and Learning", "text": "/articles/STUDY-The-Role-of-Sleep-in-Cognitive-Performance-Understanding-How-Sleep-Affects-Memory-and-Learning.txt", "genre": "Neuroscience" },
  { "id": 49, "title": "The Role of Stem Cells in Regenerative Medicine: Unlocking the Potential for Tissue Repair and Organ Regeneration", "text": "/articles/STUDY-The-Role-of-Stem-Cells-in-Regenerative-Medicine-Unlocking-the-Potential-for-Tissue-Repair-and-Organ-Regeneration.txt", "genre": "Molecular Biology" },
  { "id": 50, "title": "The Role of T-Cell Engineering in Cancer Immunotherapy: A New Approach to Targeting Solid Tumors", "text": "/articles/STUDY-The-Role-of-T-Cell-Engineering-in-Cancer-Immunotherapy-A-New-Approach-to-Targeting-Solid-Tumors.txt", "genre": "Cancer Research" },
  { "id": 51, "title": "The Role of Telomere Shortening in Age-Related Diseases: A Study of Cellular Aging and Longevity", "text": "/articles/STUDY-The-Role-of-Telomere-Shortening-in-Age-Related-Diseases-A-Study-of-Cellular-Aging-and-Longevity.txt", "genre": "Cell Biology" },
  { "id": 52, "title": "The Role of the Gut Microbiome in Shaping the Immune System: Insights into Autoimmunity and Inflammatory Diseases", "text": "/articles/STUDY-The-Role-of-the-Gut-Microbiome-in-Shaping-the-Immune-System-Insights-into-Autoimmunity-and-Inflammatory-Diseases.txt", "genre": "Immunology" },
  { "id": 53, "title": "The Stanford Prison Experiment: Understanding the Power of Situational Forces on Human Behavior", "text": "/articles/STUDY-The-Stanford-Prison-Experiment-Understanding-the-Power-of-Situational-Forces-on-Human-Behavior.txt", "genre": "Sociology" },
  { "id": 54, "title": "The Tuskegee Syphilis Study: A Case of Ethical Violations in Medical Research", "text": "/articles/STUDY-The-Tuskegee-Syphilis-Study-A-Case-of-Ethical-Violations-in-Medical-Research.txt", "genre": "Ethics in Research" },
  { 
  "id": 55, 
  "title": "The Milgram Obedience Experiment: Understanding Human Behavior and Authority", 
  "text": "/articles/STUDY-The-Milgram-Obedience-Experiment-Understanding-Human-Behavior-and-Authority.txt", 
  "genre": "Psychology" 
},
{ 
  "id": 56, 
  "title": "The Double-Slit Experiment: Unveiling the Wave-Particle Duality", 
  "text": "/articles/STUDY-The-Double-Slit-Experiment-Unveiling-the-Wave-Particle-Duality.txt", 
  "genre": "Physics" 
},
{ 
  "id": 57, 
  "title": "The Role of Deep-Sea Hydrothermal Vents in Origin of Life Research", 
  "text": "/articles/STUDY-The-Role-of-Deep-Sea-Hydrothermal-Vents-in-Origin-of-Life-Research.txt", 
  "genre": "Environmental Science" 
},
{ 
  "id": 58, 
  "title": "The Role of Epigenetics in Mental Health: How Environmental Factors Influence Gene Expression", 
  "text": "/articles/STUDY-The-Role-of-Epigenetics-in-Mental-Health-How-Environmental-Factors-Influence-Gene-Expression.txt", 
  "genre": "Genetics" 
},
{ 
  "id": 59, 
  "title": "The Role of Antibiotic Stewardship Programs in Combating Antimicrobial Resistance", 
  "text": "/articles/STUDY-The-Role-of-Antibiotic-Stewardship-Programs-in-Combating-Antimicrobial-Resistance.txt", 
  "genre": "Public Health" 
},
      ],
      searchQuery: '',
      genres: [
        'All', 'Neuroscience', 'Genetics', 'Aging & Longevity', 'Molecular Biology', 'Cell Biology',
        'Immunology', 'Cancer Research', 'Psychology', 'Public Health', 'Pharmacology',
        'Environmental Science', 'Sociology', 'Physics', 'Chemistry', 'Biotechnology',
        'Artificial Intelligence', 'Ethics in Research', 'Medical Science'
      ],
      selectedGenre: 'All',
    };
  },
  computed: {
    filteredResearchStudies() {
      const query = this.searchQuery.toLowerCase();
      return this.researchStudies.filter((study) => {
        const matchesQuery = study.title.toLowerCase().includes(query);
        const studyGenres = study.genre.split(', ');
        const matchesGenre = this.selectedGenre === 'All' || studyGenres.includes(this.selectedGenre);
        return matchesQuery && matchesGenre;
      });
    },
  },
  methods: {
    saveSearchQuery() {
      localStorage.setItem('researchSearchQuery', this.searchQuery);
    },
    goBackToResources() {
      this.$router.push('/resources');
    },
    filterByGenre(genre) {
      this.selectedGenre = genre;
      localStorage.setItem('selectedGenre_ResearchPage', genre);
    },
  },
  mounted() {
    this.searchQuery = localStorage.getItem('researchSearchQuery') || '';
    const savedGenre = localStorage.getItem('selectedGenre_ResearchPage');
    if (savedGenre) {
      this.selectedGenre = savedGenre;
    }
    this.$nextTick(() => {
      const position = localStorage.getItem('scrollPosition_ResearchPage');
      this.$refs.content.scrollTop = position ? parseInt(position, 10) : 0;
    });
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('scrollPosition_ResearchPage', this.$refs.content.scrollTop);
    localStorage.setItem('selectedGenre_ResearchPage', this.selectedGenre);
    next();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

input[type='text'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.genres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.genre-button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.research-list {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.research-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
</style>
